import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "card p-0 shadow mx-3 my-4" }
const _hoisted_2 = { class: "d-flex p-0 m-0" }
const _hoisted_3 = { class: "col-3 list-group-item py-4" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = ["value"]
const _hoisted_6 = {
  key: 0,
  class: "form-group"
}
const _hoisted_7 = {
  key: 1,
  class: "form-group"
}
const _hoisted_8 = {
  key: 2,
  class: "form-group"
}
const _hoisted_9 = {
  key: 3,
  class: "form-group"
}
const _hoisted_10 = {
  key: 0,
  class: "alert alert-secondary py-1 px-2"
}
const _hoisted_11 = {
  key: 4,
  class: "form-group"
}
const _hoisted_12 = {
  key: 5,
  class: "form-group"
}
const _hoisted_13 = {
  key: 0,
  class: "alert alert-secondary py-1 px-2"
}
const _hoisted_14 = { class: "col-3 list-group-item py-4" }
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = {
  key: 0,
  class: "form-group"
}
const _hoisted_17 = ["onClick"]
const _hoisted_18 = {
  key: 1,
  class: "form-group"
}
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "col-6 list-group-item py-4 flex-grow-1" }
const _hoisted_21 = {
  key: 0,
  class: "alert alert-info text-center"
}
const _hoisted_22 = { class: "card-footer list-group-item d-flex justify-content-between" }
const _hoisted_23 = { class: "text-center" }
const _hoisted_24 = { class: "d-flex align-items-center" }
const _hoisted_25 = {
  key: 0,
  class: "mr-4 text-danger"
}
const _hoisted_26 = {
  key: 1,
  class: "mr-4 text-success"
}
const _hoisted_27 = {
  key: 2,
  class: "mr-4"
}
const _hoisted_28 = {
  key: 3,
  class: "loader loader-sm loader-center m-0 mr-2"
}
const _hoisted_29 = ["disabled"]

import { SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { reactive, ref, watch } from 'vue'

import { useStore } from '@/store'
import {
  ConnectorConfig,
  connectorsConnexionInputs,
  connectorsTypesLabels,
  defaultExternalName
} from '@/store/modules/connector-config/types'
import { PmsiTypeEnum } from '@/store/modules/settings/types'

import AppFinessInput from './AppFinessInput.vue'
import ConnectorFlowForm from './ConnectorFlowForm.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectorForm',
  props: {
    index: {},
    connector: {}
  },
  setup(__props: any) {

const store = useStore()
const props = __props

const connectorData = reactive({ ...props.connector })
const showDeleteDropdown = ref(false)
const requestSuccess = ref(false)
const isSubmitting = ref(false)
const hasBeenEdited = ref(false)
const hasBeenSaved = ref(false)
const errorMessage = ref('')
const passwordIsEdited = ref(false)
const apiKeyIsEdited = ref(false)

watch(connectorData, function(newConnector, oldConnector) {
  hasBeenEdited.value = !_.isEqual(connectorData, props.connector)
  errorMessage.value = ''

  // reset this value only if a form value has changed
  hasBeenSaved.value = _.isEqual(connectorData, props.connector) ? hasBeenSaved.value : false

  if (connectorsConnexionInputs['externalName'].includes(connectorData.connectorType) && oldConnector.connectorType !== newConnector.connectorType) {
    connectorData.externalName = defaultExternalName[connectorData.connectorType]
  }
})

function displayPassowrdInput() {
  return !props.connector.id // if connector is new password can't be set
    || props.connector.connectorType !== connectorData.connectorType // if connector type has changed password can't be set
    || passwordIsEdited.value
}

function startPasswordEdition() {
  passwordIsEdited.value = true
  connectorData.password = ''
}

function resetPasswordEdition() {
  passwordIsEdited.value = false
  connectorData.password = null
}

function displayApiKeyInput() {
  return !props.connector.apiKeyPart
    || apiKeyIsEdited.value
}

function startApiKeyEdition() {
  apiKeyIsEdited.value = true
  connectorData.apiKey = ''
}

function resetApiKeyEdition() {
  apiKeyIsEdited.value = false
  connectorData.apiKey = null
}

function addFiness({ finess, shortDescription, settingName }) {
  const finessList = settingName === 'restricted_legal_finess'
    ? connectorData.legalFinessList
    : connectorData.geoFinessList

  if (!finessList.find((elem) => elem.finess === finess)) {
    if(settingName === 'restricted_legal_finess') {
      connectorData.legalFinessList = [...finessList, { finess, shortDescription }]
    } else {
      connectorData.geoFinessList = [...finessList, { finess, shortDescription }]
    }
  }
}

function removeFiness(finess, settingName) {
  if(settingName === 'restricted_legal_finess') {
    connectorData.legalFinessList = connectorData.legalFinessList.filter((elem) => elem.finess !== finess)
  } else {
    connectorData.geoFinessList = connectorData.geoFinessList.filter((elem) => elem.finess !== finess)
  }
}

function updateFlow(flowIndex, flowData) {
  const connectorFlows = [...connectorData.flows]
  connectorFlows[flowIndex] = flowData
  connectorData.flows = connectorFlows
}

function deleteFlow(flowIndex) {
  const connectorFlows = [...connectorData.flows]
  connectorFlows.splice(flowIndex, 1)
  connectorData.flows = connectorFlows
}

function submitConnector() {
  if(!hasBeenEdited.value) {
    return
  }

  isSubmitting.value = true
  requestSuccess.value = false

  // reset unavailable fields based on connector type
  for(const field in connectorsConnexionInputs) {
    if(!connectorsConnexionInputs[field].includes(connectorData.connectorType)) { 
      connectorData[field] = null
    }
  }
  if (connectorsConnexionInputs['externalName'].includes(connectorData.connectorType) && connectorData.externalName === '') {
    errorMessage.value = 'Le nom du PMSI est obligatoire'
    return
  }

  const requestName = props.connector.id ? 'putConnectorConfig' : 'postConnectorConfig'
  store.dispatch('connectorConfig/submitConnectorConfig', { connectorIndex: props.index, connectorData }).then((response) => {
    Object.assign(connectorData, response)
    isSubmitting.value = false
    hasBeenEdited.value = false
    hasBeenSaved.value = store.state.connectorConfig.requests[requestName].ok
    errorMessage.value = store.state.connectorConfig.requests[requestName].error?.message || ''
    resetPasswordEdition()
    resetApiKeyEdition()
  })
}

function deleteConnector() {
  showDeleteDropdown.value = false

  if(!props.connector.id) {
    store.commit('connectorConfig/deleteConnectorConfigSuccess', props.index)
  } else {
    store.dispatch('connectorConfig/deleteConnectorConfig', { connectorId: props.connector.id, connectorIndex: props.index })
  }
}

function newEmptyFlow() {
  const newFlow = { id: null, type: null, path:'', formatVersion:'', pmsiType: PmsiTypeEnum.MCO }
  connectorData.flows = [...connectorData.flows, newFlow]
}


return (_ctx: any,_cache: any) => {
  const _component_v_dropdown = _resolveComponent("v-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", _hoisted_3, [
        _cache[21] || (_cache[21] = _createElementVNode("div", { class: "pb-2 mb-3 border-bottom" }, [
          _createElementVNode("strong", null, "Connexion")
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _cache[13] || (_cache[13] = _createElementVNode("label", null, "Type de connecteur", -1)),
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((connectorData.connectorType) = $event)),
            class: "form-control"
          }, [
            _cache[12] || (_cache[12] = _createElementVNode("option", { value: null }, " --- Choisissez un type --- ", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(connectorsTypesLabels), (label, value) => {
              return (_openBlock(), _createElementBlock("option", {
                key: value,
                value: value
              }, _toDisplayString(label), 9, _hoisted_5))
            }), 128))
          ], 512), [
            [_vModelSelect, connectorData.connectorType]
          ])
        ]),
        (_unref(connectorsConnexionInputs)['externalName'].includes(connectorData.connectorType))
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _cache[14] || (_cache[14] = _createElementVNode("label", null, "Nom du logiciel PMSI", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((connectorData.externalName) = $event)),
                type: "text",
                class: "form-control"
              }, null, 512), [
                [_vModelText, connectorData.externalName]
              ])
            ]))
          : _createCommentVNode("", true),
        (_unref(connectorsConnexionInputs)['host'].includes(connectorData.connectorType))
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _cache[15] || (_cache[15] = _createElementVNode("label", null, "Hôte", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((connectorData.host) = $event)),
                type: "text",
                class: "form-control"
              }, null, 512), [
                [_vModelText, connectorData.host]
              ])
            ]))
          : _createCommentVNode("", true),
        (_unref(connectorsConnexionInputs)['isSecureSsl'].includes(connectorData.connectorType))
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _cache[17] || (_cache[17] = _createElementVNode("label", null, "Vérifier le certificat SSL", -1)),
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((connectorData.isSecureSsl) = $event)),
                class: "form-control"
              }, _cache[16] || (_cache[16] = [
                _createElementVNode("option", { value: true }, " Oui ", -1),
                _createElementVNode("option", { value: false }, " Non ", -1)
              ]), 512), [
                [_vModelSelect, connectorData.isSecureSsl]
              ])
            ]))
          : _createCommentVNode("", true),
        (_unref(connectorsConnexionInputs)['apiKey'].includes(connectorData.connectorType))
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _cache[18] || (_cache[18] = _createElementVNode("label", null, "Clé d'API", -1)),
              (!displayApiKeyInput())
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    class: "btn btn-link btn-sm float-right",
                    onClick: startApiKeyEdition
                  }, " Modifier "))
                : _createCommentVNode("", true),
              (apiKeyIsEdited.value)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    type: "button",
                    class: "btn btn-link btn-sm float-right",
                    onClick: resetApiKeyEdition
                  }, " Annuler "))
                : _createCommentVNode("", true),
              _createElementVNode("div", null, [
                (!displayApiKeyInput())
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(connectorData.apiKeyPart), 1))
                  : _createCommentVNode("", true),
                (displayApiKeyInput())
                  ? _withDirectives((_openBlock(), _createElementBlock("input", {
                      key: 1,
                      ref: "apiKeyInput",
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((connectorData.apiKey) = $event)),
                      type: "text",
                      class: "form-control"
                    }, null, 512)), [
                      [_vModelText, connectorData.apiKey]
                    ])
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        (_unref(connectorsConnexionInputs)['username'].includes(connectorData.connectorType))
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _cache[19] || (_cache[19] = _createElementVNode("label", null, "Utilisateur", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((connectorData.username) = $event)),
                type: "text",
                class: "form-control"
              }, null, 512), [
                [_vModelText, connectorData.username]
              ])
            ]))
          : _createCommentVNode("", true),
        (_unref(connectorsConnexionInputs)['password'].includes(connectorData.connectorType))
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _cache[20] || (_cache[20] = _createElementVNode("label", null, "Mot de passe", -1)),
              (!displayPassowrdInput())
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "btn btn-link btn-sm float-right",
                    onClick: startPasswordEdition
                  }, " Modifier "))
                : _createCommentVNode("", true),
              (passwordIsEdited.value)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    type: "button",
                    class: "btn btn-link btn-sm float-right",
                    onClick: resetPasswordEdition
                  }, " Annuler "))
                : _createCommentVNode("", true),
              _createElementVNode("div", null, [
                (!displayPassowrdInput())
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, " Mot de passe masqué "))
                  : _createCommentVNode("", true),
                (displayPassowrdInput())
                  ? _withDirectives((_openBlock(), _createElementBlock("input", {
                      key: 1,
                      ref: "passwordInput",
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((connectorData.password) = $event)),
                      type: "password",
                      class: "form-control"
                    }, null, 512)), [
                      [_vModelText, connectorData.password]
                    ])
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("li", _hoisted_14, [
        _cache[25] || (_cache[25] = _createElementVNode("div", { class: "pb-2 mb-3 border-bottom" }, [
          _createElementVNode("strong", null, "Filtrer par finess")
        ], -1)),
        _createElementVNode("div", _hoisted_15, [
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((connectorData.isAllFiness) = $event)),
            class: "form-control"
          }, _cache[22] || (_cache[22] = [
            _createElementVNode("option", { value: true }, " Autoriser tous les finess ", -1),
            _createElementVNode("option", { value: false }, " Filtrer par finess ", -1)
          ]), 512), [
            [_vModelSelect, connectorData.isAllFiness]
          ])
        ]),
        (!connectorData.isAllFiness)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _cache[23] || (_cache[23] = _createElementVNode("label", null, "Finess juridique", -1)),
              _createElementVNode("div", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(connectorData.legalFinessList, (finess) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: finess['finess'],
                    class: "badge-primary badge col-auto mx-1 mb-1 px-2 text-wrap"
                  }, [
                    _createElementVNode("span", null, _toDisplayString(finess['shortDescription']) + " (" + _toDisplayString(finess['finess']) + ")", 1),
                    _createElementVNode("span", {
                      class: "criteria-remove",
                      onClick: ($event: any) => (removeFiness(finess['finess'], 'restricted_legal_finess'))
                    }, [
                      _createVNode(_unref(SancareOcticon), {
                        name: "x",
                        width: 14,
                        height: 11
                      })
                    ], 8, _hoisted_17)
                  ]))
                }), 128))
              ]),
              _createVNode(AppFinessInput, {
                "setting-name": 'restricted_legal_finess',
                onSelectFiness: addFiness
              })
            ]))
          : _createCommentVNode("", true),
        (!connectorData.isAllFiness)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _cache[24] || (_cache[24] = _createElementVNode("label", null, "Finess géographique", -1)),
              _createElementVNode("div", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(connectorData.geoFinessList, (finess) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: finess['finess'],
                    class: "badge-primary badge col-auto mx-1 mb-1 pl-2 pr-2"
                  }, [
                    _createElementVNode("span", null, _toDisplayString(finess['shortDescription']) + " (" + _toDisplayString(finess['finess']) + ")", 1),
                    _createElementVNode("span", {
                      class: "criteria-remove",
                      onClick: ($event: any) => (removeFiness(finess['finess'], 'restricted_geo_finess'))
                    }, [
                      _createVNode(_unref(SancareOcticon), {
                        name: "x",
                        width: 14,
                        height: 11
                      })
                    ], 8, _hoisted_19)
                  ]))
                }), 128))
              ]),
              _createVNode(AppFinessInput, {
                "setting-name": 'restricted_geo_finess',
                onSelectFiness: addFiness
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("li", _hoisted_20, [
        _cache[26] || (_cache[26] = _createElementVNode("div", { class: "pb-2 mb-3 border-bottom" }, [
          _createElementVNode("strong", null, "Liste des flux")
        ], -1)),
        (connectorData.flows.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_21, " Attention aucun flux n'est configuré "))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(connectorData.flows, (flow, flowIndex) => {
          return (_openBlock(), _createBlock(ConnectorFlowForm, {
            key: flow.id,
            index: flowIndex,
            "connector-index": _ctx.index,
            "connector-type": connectorData.connectorType,
            flow: flow,
            onUpdateFlow: updateFlow,
            onDeleteFlow: deleteFlow
          }, null, 8, ["index", "connector-index", "connector-type", "flow"]))
        }), 128)),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-block btn-outline-primary",
          onClick: newEmptyFlow
        }, " Ajouter un flux ")
      ])
    ]),
    _createElementVNode("div", _hoisted_22, [
      _createVNode(_component_v_dropdown, {
        placement: "top",
        class: "float-right",
        shown: showDeleteDropdown.value,
        onHide: _cache[10] || (_cache[10] = ($event: any) => (!showDeleteDropdown.value))
      }, {
        popper: _withCtx(() => [
          _cache[27] || (_cache[27] = _createElementVNode("h4", { class: "title-light text-center" }, " Êtes-vous sûr ? ", -1)),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("button", {
              class: "btn btn-danger btn-padded",
              onClick: _cache[9] || (_cache[9] = ($event: any) => (deleteConnector()))
            }, " Supprimer ")
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-outline-danger",
            onClick: _cache[8] || (_cache[8] = ($event: any) => (showDeleteDropdown.value = true))
          }, " Supprimer le connecteur ")
        ]),
        _: 1
      }, 8, ["shown"]),
      _createElementVNode("div", _hoisted_24, [
        (errorMessage.value !== '')
          ? (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString(errorMessage.value), 1))
          : (hasBeenSaved.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_26, " Les modifications ont bien été enregistrées "))
            : (_ctx.connector && hasBeenEdited.value && !isSubmitting.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_27, " Des modifications sont à enregistrer "))
              : _createCommentVNode("", true),
        (isSubmitting.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_28))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-primary",
          disabled: !hasBeenEdited.value || isSubmitting.value,
          onClick: _cache[11] || (_cache[11] = ($event: any) => (submitConnector()))
        }, " Enregistrer le connecteur ", 8, _hoisted_29)
      ])
    ])
  ]))
}
}

})